import React, { useContext, useEffect, useState } from 'react';
import {
  findLastResumeBlock,
  getFirstAvailableUnit,
  getIframeURL,
} from '../hooks/utils/iframe';
import { getParameterByName } from '../hooks/helpers';
import { useParams } from 'react-router-dom';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import { GLOBAL_COLORS } from '@iblai/ibl-web-react-common';
import { IoCheckmarkCircle } from 'react-icons/io5';
import { MdOutlineRadioButtonUnchecked } from 'react-icons/md';
import './CourseIframeSideItem.css';

const CourseSideItemContext = React.createContext(null);
const CourseSideItemProvider = CourseSideItemContext.Provider;

const CourseIframeSideItem = ({
  courseData,
  setEdxIframe,
  changeIframeLoadingStatus,
  unitIdFromUrl,
  setUnitIdFromUrl,
  refresher,
}) => {
  const { courseID } = useParams();
  const [activeCourseID, setActiveCourseID] = useState(unitIdFromUrl);
  const [activeSecondLevelOutline, setActiveSecondLevelOutline] =
    useState(null);
  const [activeFirstLevelOutline, setActiveFirstLevelOutline] = useState(null);
  const [currentTree, setCurrentTree] = useState(null);
  const [expandedSections, setExpandedSections] = useState({});

  const handleAccordionClick = (index) => {
    setExpandedSections((prevExpandedSections) => ({
      ...prevExpandedSections,
      [index]: !prevExpandedSections[index],
    }));
  };

  const handleIframeUrl = (url) => {
    setEdxIframe(url);
  };

  const setHighlightOnMenu = () => {
    const unitId =
      getParameterByName('unit_id') ||
      unitIdFromUrl ||
      findLastResumeBlock(courseData)?.id ||
      getFirstAvailableUnit(courseData).id;
    setUnitIdFromUrl(unitId);
    setActiveCourseID(unitId);
    const actualCurrentTree = findFirstAndSecondLevelIndexes(
      courseData?.children,
      unitId
    );
    setCurrentTree(actualCurrentTree);
  };

  function findFirstAndSecondLevelIndexes(jsonArray, targetId) {
    for (let i = 0; i < jsonArray.length; i++) {
      let firstLevelObj = jsonArray[i];
      if (firstLevelObj.children) {
        for (let j = 0; j < firstLevelObj.children.length; j++) {
          let secondLevelObj = firstLevelObj.children[j];
          if (secondLevelObj.children) {
            for (let k = 0; k < secondLevelObj.children.length; k++) {
              let thirdLevelObj = secondLevelObj.children[k];
              if (thirdLevelObj.id === targetId) {
                return { firstLevelIndex: i, secondLevelIndex: j };
              }
            }
          }
        }
      }
    }
    return null; // If the targetId is not found
  }

  const handleOutlineClick = (parentIndex, event) => {
    const dataId = event.currentTarget.getAttribute('data-id');
    const currentUnitId = getParameterByName('unit_id');
    const firstUnitId = getFirstAvailableUnit(courseData).id;

    // If there's no unit_id in the URL and this is  the first element, return early
    if (!currentUnitId && dataId === firstUnitId) {
      return;
    }
    if (dataId !== getParameterByName('unit_id')) {
      const newURL = `/courses/${courseID}/course?unit_id=${dataId}`;
      changeIframeLoadingStatus(true);
      window.history.pushState(null, 'Course', newURL);
      setHighlightOnMenu();
      getIframeURL(courseID, courseData, handleIframeUrl);
    }
  };

  useEffect(() => {
    getIframeURL(courseID, courseData, handleIframeUrl);
    setHighlightOnMenu();
  }, [courseData, refresher]);

  return (
    <CourseSideItemProvider
      value={{
        handleOutlineClick,
        setHighlightOnMenu,
        activeCourseID,
        activeSecondLevelOutline,
        activeFirstLevelOutline,
        setActiveCourseID,
        setActiveSecondLevelOutline,
        setActiveFirstLevelOutline,
        currentTree,
        refresher,
      }}
    >
      {currentTree &&
        courseData?.children?.map((item, index) => (
          <div className="faq-question-wrap-3" key={index}>
            <CourseOutline
              key={`course-outline-${index}`}
              item={item}
              index={index}
            />
          </div>
        ))}
    </CourseSideItemProvider>
  );
};

const CourseOutline = ({ item, index }) => {
  const { currentTree, refresher } = useContext(CourseSideItemContext);
  const [open, setIsOpen] = useState(false);
  useEffect(() => {
    setIsOpen(currentTree?.firstLevelIndex === index);
  }, [currentTree]);
  return (
    <div className="faq-question-wrap-3" key={index}>
      <a
        data-collapsed={'true'}
        href="#"
        className="first-level-outline faq-question-bar-3 w-inline-block"
        onClick={() => setIsOpen(!open)}
      >
        <div className="div-block-632 w-clearfix">
          {open ? (
            <FaChevronRight size={15} color={GLOBAL_COLORS.primary} />
          ) : (
            <FaChevronDown size={15} color={GLOBAL_COLORS.primary} />
          )}
          <div className="question-title-4 f">{item.display_name}</div>
        </div>
      </a>
      {open &&
        Array.isArray(item.children) &&
        item.children.map((childItem, childIndex) => {
          return (
            Array.isArray(childItem.children) &&
            childItem.children.length > 0 && (
              <SecondLevelOutline
                index={`${index}-${childIndex}`}
                key={`${index}-${childIndex}`}
                innerChildItem={childItem}
                parentIndex={index}
                properIndex={childIndex}
              />
            )
          );
          /*childItem.children.length > 1 ?
              <SecondLevelOutline defaultOpen={childIndex===0} index={`${index}-${childIndex}`} key={`${index}-${childIndex}`} innerChildItem={childItem} />
              :
              <ThirdLevelOutline  defaultOpen={childIndex===0} index={`${index}-${childIndex}`} key={`${index}-${childIndex}`} data={childItem} />*/
        })}
    </div>
  );
};

const SecondLevelOutline = ({
  innerChildItem,
  index,
  parentIndex,
  properIndex,
}) => {
  const { currentTree, refresher } = useContext(CourseSideItemContext);
  const [open, setIsOpen] = useState(false);
  useEffect(() => {
    setIsOpen(
      currentTree?.firstLevelIndex === parentIndex &&
        currentTree?.secondLevelIndex === properIndex
    );
  }, [currentTree]);
  return (
    <>
      <div
        data-collapsed={'true'}
        className="second-level-outline faq-question-bar-3 w-inline-block"
        onClick={() => setIsOpen(!open)}
      >
        <div className="div-block-632 w-clearfix">
          {open ? (
            <FaChevronRight size={15} color={GLOBAL_COLORS.primary} />
          ) : (
            <FaChevronDown size={15} color={GLOBAL_COLORS.primary} />
          )}
          <div className="question-title-4 f">
            {innerChildItem.display_name}
          </div>
        </div>
      </div>
      {open &&
        Array.isArray(innerChildItem.children) &&
        innerChildItem.children.map((innerGrandChildItem, grandChildIndex) => (
          <ThirdLevelOutline
            defaultOpen={grandChildIndex === 0}
            index={grandChildIndex}
            key={`${index}-${grandChildIndex}`}
            data={innerGrandChildItem}
          />
        ))}
    </>
  );
};

const ThirdLevelOutline = ({ data, index, defaultOpen = false }) => {
  const [open, setIsOpen] = useState(defaultOpen);
  const { handleOutlineClick, activeCourseID } = useContext(
    CourseSideItemContext
  );

  return (
    <div className={`third-level-outline faq-content-3 open`}>
      <div className={`inner open`}>
        <div
          data-id={data.id}
          //className={`module_row ${isActive ? 'active' : ''}`}
          className={`module_row ${activeCourseID === data.id ? 'active' : ''}`}
          onClick={(event) => handleOutlineClick(index, event)}
        >
          <div className="div-block-632 w-clearfix">
            <div className="inner_text">
              {data?.scored ? (
                <IoCheckmarkCircle
                  color={GLOBAL_COLORS.primary}
                  size={17}
                  style={{
                    minWidth: '17px',
                  }}
                />
              ) : (
                <MdOutlineRadioButtonUnchecked
                  color={GLOBAL_COLORS.primary}
                  size={17}
                  style={{
                    minWidth: '17px',
                  }}
                />
              )}

              <div
                //className={`c_l ${isActive ? 'active-link' : ''}`}
                className={`c_l`}
              >
                {data.display_name}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseIframeSideItem;
