import { getUserId, getUserName } from '../helpers';
import api from '../../utils/api';

export function getCourseDetails(courseID, callback) {
  let data = {
    course_id: courseID,
  };

  api.ibledxcourses.getCourseOutline(data, (_data) => {
    callback(_data);
  });
}

export function getUserCourseCompletionApi(courseID, callback) {
  let data = {
    course_id: courseID,
    user_id: getUserId(),
  };
  api.ibldmcourses.getUserCourseCompletion(data, function (_data) {
    callback(_data);
  });
}

export function getUserProgress(courseID, callback) {
  let data = {
    course_id: courseID,
    username: getUserName(),
  };

  api.ibldmcourses.fetchUserProgress(data, function (data) {
    callback(data);
  });
}

export function getCourseGradeProgress(courseID, callback) {
  const data = {
    course_id: courseID,
    user_id: getUserId(),
  };

  api.ibledxcourses.get_user_course_grade_progress(data, callback);
}


export const  getCourseGradeDetails = (courseID, callback) =>{
  const url = `${process.env.REACT_APP_IBL_LMS_URL}/api/course_home/progress/${courseID}`
  fetch(url, {
    method:"GET",
    headers:{
      'Content-Type': 'application/json',
    },
    credentials:"include"
  }).then((resp)=>{
    resp.json().then((data)=>{
      callback(data)
    })
  }).catch(()=>{
    callback(null)
  })
}