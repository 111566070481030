import React, { useState } from 'react';
import CourseIframeSideItem from './CourseIframeSideItem';
import { GLOBAL_COLORS } from '@iblai/ibl-web-react-common';
import {
  TbLayoutSidebarLeftCollapse,
  TbLayoutSidebarRightCollapse,
} from 'react-icons/tb';
import SlDrawer from '@shoelace-style/shoelace/dist/react/drawer';

const CourseIframeSideMenu = ({
  courseData,
  setEdxIframe,
  changeIframeLoadingStatus,
  unitIdFromUrl,
  setUnitIdFromUrl,
  handleMenuCollapse,
  isMobile = false,
  refresher,
  mobileCourseOutlineOpen,
  setMobileCourseOutlineOpen,
}) => {
  const [isExpanded, setIsExpanded] = useState(!isMobile);

  const handleCollapseClick = () => {
    setIsExpanded(!isExpanded);
    handleMenuCollapse(!isExpanded);
  };

  //const [mobileCourseOutlineOpen, setMobileCourseOutlineOpen] = useState(false);

  return (
    <>
      {isMobile ? (
        <>
          <SlDrawer
            style={{ '--size': '60%' }}
            label="Course Outline"
            placement="bottom"
            open={mobileCourseOutlineOpen}
            onSlAfterHide={() => setMobileCourseOutlineOpen(false)}
          >
            <div className={'course_inside_menu course_inside_menu_mobile'}>
              <div className={'left_m'}>
                <div className="left_m_w">
                  <div className="faq-column-wrapper">
                    <CourseIframeSideItem
                      courseData={courseData}
                      setEdxIframe={setEdxIframe}
                      changeIframeLoadingStatus={changeIframeLoadingStatus}
                      unitIdFromUrl={unitIdFromUrl}
                      setUnitIdFromUrl={setUnitIdFromUrl}
                      refresher={refresher}
                    />
                  </div>
                </div>
              </div>
            </div>
          </SlDrawer>
          {/*<SlButton  className={'course-outline-mobile-trigger-btn'}
                      variant="default" outline>
              Open course outline
            </SlButton>*/}
        </>
      ) : (
        <div
          className={`course_inside_menu ${!isExpanded ? 'unexpanded' : ''}`}
        >
          <div className="left_m" style={{ width: isExpanded ? '255px' : 0 }}>
            <div className="left_m_w">
              <div className="faq-column-wrapper">
                <CourseIframeSideItem
                  courseData={courseData}
                  setEdxIframe={setEdxIframe}
                  changeIframeLoadingStatus={changeIframeLoadingStatus}
                  unitIdFromUrl={unitIdFromUrl}
                  setUnitIdFromUrl={setUnitIdFromUrl}
                  refresher={refresher}
                />
              </div>
            </div>
          </div>
          <div className="left_m-copy right">
            <a
              id="coll_btn"
              href="#"
              className="collapse_h w-inline-block course-outline-uncollapser"
              onClick={handleCollapseClick}
            >
              {!isExpanded ? (
                <TbLayoutSidebarRightCollapse
                  size={25}
                  color={GLOBAL_COLORS.primary}
                />
              ) : (
                <TbLayoutSidebarLeftCollapse
                  size={25}
                  color={GLOBAL_COLORS.primary}
                />
              )}
            </a>
          </div>
        </div>
      )}
    </>
  );
};
export default CourseIframeSideMenu;
