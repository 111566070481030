import React, { useEffect, useState } from 'react';
import { processFilterData } from '../../hooks/helpers';
import './FilterTags.css';

const FilterTags = ({
  selectedTerms,
  setSelectedTerms,
  facetHandler,
  discoverItems,
  setDiscoverLoaded,
}) => {
  const [processedData, setProcessedData] = useState();

  useEffect(() => {
    setProcessedData(processFilterData(selectedTerms));
  }, []);

  const handleTagClose = (facetName, term) => {
    const processedTerm = `${facetName}:${term}`;
    setSelectedTerms((prevSelectedTerms) => {
      const newSelectedTerms = prevSelectedTerms.filter(
        (selectedTerm) => selectedTerm !== processedTerm
      );
      setDiscoverLoaded(false);
      facetHandler(newSelectedTerms);
      setProcessedData(processFilterData(newSelectedTerms));
      return newSelectedTerms;
    });
  };

  return (
    <div>
      {processedData && (
        <div className="w-layout-hflex filter-summary-block">
          {Object.keys(processedData).map((key) => (
            <div
              className="w-layout-hflex filter-summary-box"
              data-name={key}
              key={key}
            >
              <div className="filter-tag-title">{key}:</div>
              <div className="filter-tag-content">
                {processedData[key].map((term, index) => (
                  <span key={index} className="filter-term">
                    {key === 'providers'
                      ? discoverItems?.providers?.terms[
                          term
                        ].name.toUpperCase() || term
                      : term}
                    <img
                      alt=""
                      onClick={() => handleTagClose(key, term)}
                      className="filter-tag-closer-icon"
                      loading="lazy"
                      src="/images/x-1.svg"
                    />
                  </span>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FilterTags;
