import React, { useEffect, useState } from 'react';
import { NavLink, Outlet, useParams } from 'react-router-dom';
import './CourseIframeContentView.css';
import { getCourseTabs } from '../../../hooks/utils/iframe';
import { CourseDetailContext } from '../../../hooks/utils/courseDetail';
import { getCourseGradingPolicy } from '../../../hooks/helpers';

const CourseIframeContentView = () => {
  const { courseID } = useParams();
  const [tabs, setTabs] = useState([]);
const [completionData, setCompletionData] = useState(null);
const [gradingPolicyActive, setGradingPolicyActive] =  useState(false)


  useEffect(() => {
    const handleTabs = (tabsData) => {
      setTabs(tabsData);
    };
    getCourseTabs(courseID, handleTabs);
    //getCourseGradingPolicy(courseID, handleCheckIfCourseIsGraded)
  }, [courseID]);

  const handleCheckIfCourseIsGraded = (data) => {
    console.log("Is Graded : ", data)
  }

  return (
    <>
      <div className={`course-title-top-menu-block ${process.env.REACT_APP_IBL_ENABLE_MENTOR ? "mentor-modal-available" : ""}`} style={{ marginTop: '76px' }}>
        <div className="div-block-630">
          <NavLink
            to={`/courses/${courseID}/course`}
            className={({ isActive, isPending }) =>
              `link-options-3 ${isPending ? 'pending' : isActive ? 'active' : ''}`
            }
          >
            Course
          </NavLink>

          {tabs?.map((tab) => {
            if (tab.title === 'Course' || tab.title === 'Instructor') return null;
            return (
              <NavLink
                key={tab.tab_id}
                to={`/courses/${courseID}/${tab.tab_id}`}
                className={({ isActive, isPending }) =>
                  `link-options-3 ${isPending ? 'pending' : isActive ? 'active' : ''}`
                }
              >
                {tab.title}
              </NavLink>
            );
          })}
        </div>
        <div className="div-block-63 top-menu-block">
          <div className="toolbar-div progress-div" style={{marginRight:gradingPolicyActive ? "unset" : "12px" }}>
            <div className="div-block-61">
              <div className="div-block-62" style={{width:`${completionData?.completion_percentage ?? 0}%`}}></div>
            </div>
            <div className="text-block-46">{completionData?.completion_percentage ?? 0}%</div>
            <div className="progress-hover">
              <div className="div-block-64"><img
                src="https://assets.website-files.com/6273fa252a23566434010010/63a332d74bf7fc679303f80d_clock.png"
                loading="lazy" alt="" className="image-42" /></div>
              <div>
                <div className="text-block-47">Time Spent</div>
                <div className="text-block-48">12h 30m</div>
              </div>
              <div className="tr ts"></div>
            </div>
          </div>
          {
            gradingPolicyActive && (
              <div className="toolbar-div grade">
                <div className="text-block-45 zm grade-text">Grade</div>
                <div className="text-block-49">{completionData?.grading_percentage ?? 0}%</div>
              </div>
            )
          }
          {/*<div className="toolbar-div skills-div"><a href="#" id="about"
                                                     className="secondary_link sm sklls plus_skills">Time Spent</a>
            <div className="text-block-44 bl pp"><span className="text-span">●</span> 12h 30m</div>
          </div>*/}
        </div>

      </div>
      <CourseDetailContext.Provider value={{setCompletionData, setGradingPolicyActive}}>
        <Outlet />
      </CourseDetailContext.Provider>
    </>
  );
};

export default CourseIframeContentView;
