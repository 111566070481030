import { Outlet } from 'react-router-dom';
import { ProfileMenu, VisibilityContext } from '@iblai/ibl-web-react-common';
import { inIframe } from '../utils/helpers';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';

const ProfileView = () => {
  const { t } = useTranslation();
  const { visibilityData } = useContext(VisibilityContext);
  let menu = [
    {
      link: '/profile/activity',
      name: t('Activity'),
    },
    {
      link: '/profile/skills',
      name: t('Skills'),
    },
    {
      link: '/profile/credentials',
      name: t('Credentials'),
    },
    /*{
      link: '/profile/assigned-content',
      name: t('Assigned Content'),
    },*/
    {
      link: '/profile/pathways',
      name: t('Pathways'),
    },
    {
      link: '/profile/programs',
      name: t('Programs'),
    },
    {
      link: '/profile/courses',
      name: t('Courses'),
    },
    {
      link: '/profile/public-profile',
      name: t('Public Profile'),
    },
  ];

  if (process.env.REACT_APP_ACTIVE_PROFILE_TABS) {
    menu = menu.filter((menuItem) =>
      process.env.REACT_APP_ACTIVE_PROFILE_TABS.split(',').includes(
        menuItem.name
      )
    );
  }
  return (
    <div className="panels" style={inIframe() ? { paddingTop: '8px' } : {}}>
      <div
        className="panels_cont"
        style={inIframe() ? { paddingTop: '0px', margin: 0 } : {}}
      >
        <div
          className="panel-profile panel"
          style={
            inIframe() ? { paddingTop: '0px', margin: 0, width: '100%' } : {}
          }
        >
          {visibilityData.showProfileNav && (
            <div className="div-block-421">
              <div className="profile_menu">
                <ProfileMenu menu={menu}></ProfileMenu>

                <div className="prof_menu hidden" data-name="feed">
                  Feed
                </div>
              </div>
            </div>
          )}
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default ProfileView;
