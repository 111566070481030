import Contentfilterbox from '../../components/Contentfilterbox/Contentfilterbox';
import React, { useEffect, useState } from 'react';

import Coursesfilterbox from '../../components/Coursesfilterbox';
import ReactPaginate from 'react-paginate';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';
import useDiscover from '../../hooks/useDiscover';
import FilterTags from '../../components/FilterTags/FilterTags';
import { useTranslation } from 'react-i18next';

const BrowseContentView = () => {
  const {
    discoverItems,
    discoverLoaded,
    setDiscoverItems,
    setDiscoverLoaded,
    selectedTerms,
    setSelectedTerms,
    totalPages,
    setTotalPages,
    convertTermToDict,
    updateQueryParams,
    getFacetRequestObject,
    currentPage,
    setCurrentPage,
    paginate,
    contentFilterBoxItems,
    setContentFilterBoxItems,
  } = useDiscover();

  const { t } = useTranslation();

  const facetHandler = (newSelectedTerms) => {
    if (!newSelectedTerms.find((term) => term.split(':')[0] === 'content')) {
      newSelectedTerms.unshift(`content:courses`);
    }
    const termsDict = convertTermToDict(newSelectedTerms);
    updateQueryParams(termsDict);
  };

  return (
    <span>
      <div className="panels">
        <div className="panels_cont">
          <div className="panel-browse panel">
            <div className="browse">
              <div className="browse_panel active" data-name="browse">
                <div className="browse_cont">
                  <div className="browse_left">
                    <div className="prof_box browse">
                      <Contentfilterbox
                        discoverItems={contentFilterBoxItems}
                        selectedTerms={selectedTerms}
                        setSelectedTerms={setSelectedTerms}
                        facetHandler={facetHandler}
                        setDiscoverLoaded={setDiscoverLoaded}
                        isLoading={!discoverLoaded}
                      />
                    </div>

                    <div className="prof_box grey s hidden">
                      <div className="div-block-376">
                        <img
                          alt=""
                          className="image-127"
                          loading="lazy"
                          src="images/plus-2_1plus-2.png"
                        />
                      </div>
                      <div className="text-block sec_title ex_co amc">
                        Add My Content
                      </div>
                    </div>
                  </div>
                  <div className="br_panel" data-name="top">
                    <div className="text-block-308">
                      {t('Featured Learning Content')}
                    </div>
                    {!discoverLoaded ? (
                      <IBLSpinLoader size={40} containerHeight={'300px'} />
                    ) : (
                      <>
                        <FilterTags
                          selectedTerms={selectedTerms}
                          discoverItems={contentFilterBoxItems}
                          setSelectedTerms={setSelectedTerms}
                          facetHandler={facetHandler}
                          setDiscoverLoaded={setDiscoverLoaded}
                        />
                        <div className="div-block-430 sl pathway_grid">
                          <Coursesfilterbox
                            discoverItems={discoverItems}
                            discoverLoaded={discoverLoaded}
                          />
                        </div>
                        {discoverLoaded && totalPages > 1 && (
                          <ReactPaginate
                            onPageChange={paginate}
                            pageCount={totalPages}
                            previousLabel={'Prev'}
                            nextLabel={'Next'}
                            containerClassName={'pagination m-t-20'}
                            pageLinkClassName={'page-number'}
                            previousLinkClassName={'page-number'}
                            nextLinkClassName={'page-number'}
                            activeLinkClassName={'active'}
                            forcePage={currentPage - 1}
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </span>
  );
};

export default BrowseContentView;
